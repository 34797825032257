<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'users'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-alert
        v-if="userData.enabled && !userData.currently_active"
        variant="warning"
        show
      >
        <div class="alert-body">
          This user's organisation
          <b-link
            class="alert-link"
            :to="{ name: 'orgs-view', params: { id: userData.organisation.id } }"
          >
            {{ userData.organisation.name }}
          </b-link> is inactive.
        </div>
      </b-alert>
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <user-view-user-info-card :user-data="userData" />
          <user-view-user-timeline-card />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <permissions-groups-card
            :permissions="userData.organisation.permissions"
            :can-view="userData.can_view"
          />
        </b-col>
      </b-row>

    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
import PermissionsGroupsCard from '../../../layouts/components/PermissionGroupsCard.vue'



export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserTimelineCard,
    PermissionsGroupsCard,

  },
  setup() {
    const userData = ref(null)

    store.dispatch('app/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
        userData.value = response.data.results[0]
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    return {
      userData,
    }
  },
}
</script>

<style>

</style>
