import jwtDefaultConfig from './jwtDefaultConfig'
import router from '@/router'
import { FrontendApi, Configuration } from "@ory/client";
import { $themeConfig } from '@/../themeConfig'


export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  /**
   * @type {FrontendApi}
   */
  ory = null;

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    this.ory = new FrontendApi(
        new Configuration({
          basePath: $themeConfig.app.oryUrl,
          baseOptions: {
            withCredentials: true,
          }
        })
    );

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        config.withCredentials = true;
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (originalRequest.url.includes('/users/me')) {
            return Promise.reject(error)
          }
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  async loadUserData() {
    try {
      const session = await this.getOrySession();
      if (session == null) {
        throw new Error('Error getting session');
      }
      const response = await this.axiosIns.get('/users/me');
      if (response.status !== 200) {
        throw new Error('Error getting user data');
      }

      const res = response.data.results[0];
      res['logoutUrl'] = session.logoutUrl;
      localStorage.setItem('userData', JSON.stringify(res));
      return res;

    } catch (err) {
      console.log(err)
      this.clearUserData();
      if (err.response && err.response.status === 401 && err.response.request.responseURL.includes('/users/me')) {
        await router.push({
          name: 'error-msg',
          params: {
            error: `User not found`,
            message: `Your user was not found in DWG. Please contact the administrator.`,
          },
        })
      }
      return null;
    }
  }

  getUserData() {
    return JSON.parse(localStorage.getItem('userData'))
  }

  clearUserData() {
    localStorage.removeItem('userData')
  }

  async getOrySession() {
    try {
      const sessionRes = await this.ory.toSession();
      const logoutUrlRes = await this.ory.createBrowserLogoutFlow({returnTo: `${window.location.origin}/login`});
      sessionRes.data['logoutUrl'] = `${logoutUrlRes.data.logout_url}`;
      return sessionRes.data;
    } catch (e) {
      console.error('ORY ERROR');
      console.error(e);
      return null;
    }
  }

  refreshToken() {
    router.push({ name: 'login', query: { redirectFrom: router.currentRoute.fullPath } })
  }

}
