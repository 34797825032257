<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.photo_url"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.name }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'users-edit', params: { id: userData.id } }"
                variant="primary"
              >
                Edit
              </b-button>
              <div v-if="userData.id !== sessionUserData.id">
                <b-button
                  id="deleteTooltip" href="#"
                  variant="outline-danger"
                  class="ml-1"
                >
                  Delete
                </b-button>
                <b-tooltip ref="deleteTooltip" target="deleteTooltip" title="Tooltip title" triggers="click">
                  <div>Are you sure?</div> <b-link class="font-weight-bolder" @click="closeDeleteTooltip">Cancel</b-link> - <b-link class="text-danger font-weight-bolder" @click="deleteThis">Delete</b-link>
                </b-tooltip>
              </div>
            </div>
          </div>
        </div>


      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Company</span>
            </th>
            <td class="pb-50">
              <b-link
                :to="{ name: 'orgs-view', params: { id: userData.organisation.id } }"
              >
                {{ userData.organisation.name }}
              </b-link>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Active</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge :variant="(userData.enabled) ? 'success' : 'danger'">
                {{ (userData.enabled) ? 'Active' : 'Disabled' }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Role</span>
            </th>
            <td class="pb-50 text-capitalize">
              <div class="text-nowrap">
                <feather-icon
                  :icon="resolveUserRoleIcon(userData.role)"
                  size="18"
                  class="mr-50"
                  :class="`text-${resolveUserRoleVariant(userData.role)}`"
                />
                <span class="align-text-top text-capitalize">{{ userData.role }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country</span>
            </th>
            <td class="pb-50">
              <img
                :src="`https://flagcdn.com/h20/${userData.country}.png`"
                :srcset="`https://flagcdn.com/h40/${userData.country}.png 2x,
                  https://flagcdn.com/h60/${userData.country}.png 3x`"
                height="16"
                :alt="getCountryName(userData.country)"
                class="mr-50"
              >
              <span>{{ getCountryName(userData.country) }}</span>
            </td>
          </tr>
        </table>


      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BButton, BAvatar, BRow, BCol, BLink, BTooltip,
} from 'bootstrap-vue'
//import { avatarText } from '@core/utils/filter'
import useUsersList from '../users-list/useUsersList'
import router from '@/router'
import store from '@/store'
import countries from '@/countries-data'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { $themeConfig } from '@/../themeConfig'

import useJwt from '@/auth/jwt/useJwt'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BBadge, BCard, BButton, BRow, BCol, BAvatar, BLink, BTooltip,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeDeleteTooltip() {
      this.$refs.deleteTooltip.$emit('close')
    },
    getCountryName(countryCode) {
      return countries.find(country => country.value === countryCode).label
    },
  },
  setup() {
    // Use toast
    const toast = useToast()

    const {
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
    } = useUsersList()

    const deleteThis = () => {
      let userId = router.currentRoute.params.id

      store.dispatch('app/deleteUser', { id: userId })
        .then(() => {
          // Redirect to content items list
          router.push({ name: 'users' })
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating new user.',
              text: 'ERROR: ' + error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    
    const sessionUserData = ref({})
    sessionUserData.value = useJwt.getUserData()


    return {
      //avatarText,
      deleteThis,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      $themeConfig,
      sessionUserData
    }
  },
}
</script>

<style>

</style>
